import React from 'react'
import styled from 'styled-components'
import { Input, Button } from '../../Form'
import Container from './WidgetContainer'

const Box = styled.div`
  @media screen and (min-width: 1000px) {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-between;
    div {
      align-self: center;
      flex: 10 1 auto;
      margin: 0;
      input {
        align-self: center;
        margin: 0;
        margin-right: 2em;
      }
    }
    button {
      align-self: center;
      flex: 1 1 auto;
    }
  }
`
const MailingList = () => (
  <Container girdArea="signup">
    <h5>Monthly Newsletter</h5>
    <p>Please enter your email below to sign up to our monthly newsletter.</p>
    <Box>
      <Input type="email" placeholder="Email" />
      <Button buttonType="primary" title="subscribe" />
    </Box>
    <small>*By subscribing to our news letter you confirm you have read and agree to our Privacy Policy.</small>
  </Container>
)

export default MailingList
