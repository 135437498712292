import React from 'react'
import styled from 'styled-components'
import Navigation from '../Navigation'
import { GlobalStyles } from '../utils/GlobalStyles'
import Theme from '../utils/Theme.js'
import SEO from '../utils/seo'
import Container from './Container'
import Header from '../Header/MainHeader'
import Footer from '../Footer/Footer'
import RotateMessage from '../RotateMessage/RotateMessage'
import { PrivacyBanner } from '../Banners'

const Wrapper = styled.div`
  height: 100%;
`
const DefaultLayout = ({ title, children }) => (
  <Theme>
    <>
      <RotateMessage />
      <PrivacyBanner />
      <Wrapper>
        <GlobalStyles />
        <SEO title={title} />
        <Header />
        <Container>{children}</Container>
        <Navigation />
        <Footer />
      </Wrapper>
    </>
  </Theme>
)

export default DefaultLayout
