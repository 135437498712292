import React from 'react'
import NavBar from './NavBar'
const Navigation = () => {
  return (
    <NavBar>
      
    </NavBar>
  )
}

export default Navigation
