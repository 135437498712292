import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: calc(16px + (64 - 16) * (100vw - 325px) / (2000 - 325)) auto;
  max-width: 1400px;
`
const HeaderContainer = ({ children }) => <Container>{children}</Container>

export default HeaderContainer
