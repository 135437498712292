import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components'

const Image = styled(Img)`
  /* width: calc( 80px + (100 - 60)*(100vw - 400px)/(1000 - 400)); */
  height: 100%;
  max-width: 1200px;
  bottom: 0;
  @media screen and (min-width: 1200px) {
    margin-left: calc(100vw - 1200px)
  }
`

const LandingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "landing-page-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Image fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default LandingPage
