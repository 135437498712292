import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { TimelineMax, Linear } from 'gsap'
import styled from 'styled-components'

const Nav = styled.nav`
  display: none;
  @media screen and (min-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 3.5em;
    h5 {
      position: absolute;
      top: 16px;
      right: 0;
      border-radius: 50px 0 0 50px;
      background-color: ${props => props.theme.primary};
      color: ${props => props.theme.white};
      padding: 1.2em;
      padding-right: 3em;
    }
  }
`
const LinkContainer = styled.ul`
  display: flex;
  flex-direction: row;
  li {
    cursor: pointer;
    margin-left: 2em;
    transform: translateY(0);
    transition: 0.3s;
    :hover {
      transform: translateY(-2px);
      transition: 0.3s;
      a {
        color: ${props => props.theme.lightSlate};
      }
    }
  }
`

const NavLink = styled(Link)`
  color: ${props => props.theme.slate};
  width: 100%;
  height: 100%;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.3s;
  padding: 0.5em;
  :hover {
    transition: 0.3s;
  }
  &.active {
    border-bottom: 3px solid ${props => props.theme.primary};
  }
`
const HeaderNav = () => {
  const tl = new TimelineMax()
  useEffect(() => {
    tl.staggerFrom('.header-nav-link', 0.3, { opacity: 0, y: -50, ease: Linear.easeInOut }, 0.1)
  }, [tl])
  return (
    <Nav>
      <h5>Call 0800 368 9329</h5>
      <LinkContainer className="header-nav">
        <li>
          <NavLink to="/" activeClassName="active" className="header-nav-link">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" activeClassName="active" className="header-nav-link">
            About
          </NavLink>
        </li>
        <li>
          <NavLink to="/services" activeClassName="active" className="header-nav-link">
            Services
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" activeClassName="active" className="header-nav-link">
            Contact
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/news" activeClassName="active" className="header-nav-link">
            News
          </NavLink>
        </li>
        <li>
          <NavLink to="/careers" activeClassName="active" className="header-nav-link">
            Careers
          </NavLink>
        </li> */}
      </LinkContainer>
    </Nav>
  )
}
export default HeaderNav
