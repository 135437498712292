import React from 'react'
import styled, { keyframes } from 'styled-components'
import { ContactIcon } from '../Icons'

const pulse = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  99% {
    transform: scale(.95);
    opacity: 0;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
`

const Button = styled.a`
  display: flex;
  z-index: 100;
  align-content: center;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  left: calc(50% - 40px);
  right: calc(50% - 40px);
  background-color: ${props => props.theme.medium};
  border-radius: 50%;
  height: 80px;
  width: 80px;
  :before,
  :after {
    content: '';
    opacity: 0;
    display: block;
    position: absolute;
    border: 50%;
    border: 2px solid ${props => props.theme.medium};
    left: -20px;
    right: -20px;
    top: -20px;
    bottom: -20px;
    border-radius: 50%;
    animation: ${pulse} 1.5s linear infinite;
  }
  :after {
    animation: ${pulse} 1.5s 0.5s linear infinite;
  }
`

const PhoneButton = () => (
  <Button aria-label="link that when clicked will call Milaco" href="tel:+448003689329">
    <ContactIcon width="35px" fill="#fff" />
  </Button>
)

export default PhoneButton
