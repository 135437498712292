import React from 'react'

export default function MobileIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' width={props.width ? props.width : '15px'} fill={props.fill ? props.fill : '#fff'}>
      <path className='st0' d='M272,0H48C21.5,0,0,21.5,0,48v416c0,26.5,21.5,48,48,48h224c26.5,0,48-21.5,48-48V48C320,21.5,298.5,0,272,0z M160,480c-17.7,0-32-14.3-32-32s14.3-32,32-32s32,14.3,32,32S177.7,480,160,480z M272,372c0,6.6-5.4,12-12,12H60	c-6.6,0-12-5.4-12-12V60c0-6.6,5.4-12,12-12h200c6.6,0,12,5.4,12,12V372z'
      />
    </svg>
  )
}
