import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`
const Progbar = styled.ul`
  display: flex;
  justify-content: space-evenly;
  padding: 2em 0;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
`

const Step = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: .75em;
  color: ${props => props.theme.white};

  :before {
    text-align: center;
    display: block;
    content:"";
    font-size: 1.8em;
    font-weight: bold;
    width: 35px;
    height: 35px;
    border: 5px solid ${props => props.theme.white};
    border-radius: 50%;
    margin-bottom: .5em;
    background-color: ${props => props.theme.medium};
  }

  :after {
    content: '';
    display: flex;
    position: absolute;
    width: 180%;
    height: 5px;
    background-color: ${props => props.theme.white};
    left: 50%;
    top: 17.5px;
    z-index: -1;
  }

  :last-child:after {
    content: '';
    background-color: ${props => props.theme.medium};
  } 
`

const ActiveStep = styled(Step)`
  :before {
    background-color: ${props => props.theme.white};
  }
`

// const ActiveStep = styled(Step)`
//   color: ${props => props.theme.medium};

//   :before {
//     border: 4px solid ${props => props.theme.medium};
//     background-color: ${props => props.theme.medium};
//   }

//   :after {
//     background-color: ${props => props.theme.medium};
//   }
// `

class ProgressBar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <Container>
        <Progbar>
          {this.props.steps && this.props.steps.map((step, index) => {
            if (this.props.currentStep >= index) {
              return <ActiveStep key={index}>{step}</ActiveStep>
            } else {
              return <Step key={index}>{step}</Step>
            }
          })}
        </Progbar>
      </Container>
    )
  }
}

export default ProgressBar