import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { CloseIcon } from '../Icons'

const Container = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 3000;
  @media screen and (min-width: 1000px) {
  }
`
const Close = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 3%;
  right: 3%;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  :hover {
    transition: all 0.3s;
    opacity: 0.5;
  }
  svg {
    margin-right: 0.5em;
  }
`

const Modal = ({ isOpen, hide, children }) => {
  if (isOpen) {
    const element = ReactDOM.createPortal(
      <>
        <Container>
          <Close onClick={hide}>
            <CloseIcon fill="#fff" width="2ch" />
            Close
          </Close>
          {children}
        </Container>
      </>,
      document.body
    )
    return element
  }
}
export default Modal
