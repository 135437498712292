import React from 'react'
import styled from 'styled-components'
import { RightIcon as Icon } from '../../Icons'

const Button = styled.button`
  display: flex;
  align-items: center;
  width: initial;
  color: ${props => props.color || props.theme.primary};
  transition: all 0.3s;
  svg {
    display: block;
    margin-left: 0.8em;
    transition: all 0.3s;
  }

  :hover,
  :focus {
    background-color: ${props => props.theme.medium};
    svg {
      margin-left: 1em;
      transition: all 0.3s;
    }
  }
`

const RightIcon = styled(Icon)`
  display: block;
  margin-left: 0.8em;
  transition: all 0.3s;
  :hover,
  :focus {
    fill: ${props => props.theme.highlight};
    transition: all 0.3s;
  }
  :active {
    fill: ${props => props.theme.primary};
    transition: all 0.3s;
  }
`
const NextButton = props => (
  <Button {...props}>
    {props.title}
    <RightIcon fill={props.color} width="12px" />
  </Button>
)

export default NextButton
