import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.success};
  color: ${props => props.theme.successText};
  transition: all 0.3s;

  :hover,
  :focus {
    background-color: #48a9a6;
    color: ${props => props.theme.white};
    transition: all 0.3s;
  }

  :active {
    background-color: ${props => props.theme.success};
    transition: all 0.3s;
  }
`
const ValidButton = props => <Button {...props}>{props.title}</Button>

export default ValidButton
