import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background-color: ${props => props.theme.error};
  color: ${props => props.theme.white};
  border-color: ${props => props.theme.error};
  transition: all .3s;
  
  :hover,
  :focus {
    background-color: #DB504A;
    border-color: #DB504A;
    transition: all .3s;
  }

  :active {
    background-color: ${props => props.theme.error};
    border-color: ${props => props.theme.error};
    transition: all .3s;
  }
`
const ErrorButton = (props) => {
  return (
    <Button {...props}>{props.title}</Button>
  )
}

export default ErrorButton
