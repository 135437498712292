import React from 'react'

const AppStoreGoogle = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 10 140 40" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1="31.8"
        x2="15.02"
        y1="183.29"
        y2="166.51"
        gradientTransform="matrix(1 0 0 -1 0 202)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#00a0ff" />
        <stop offset="0.01" stopColor="#00a1ff" />
        <stop offset="0.26" stopColor="#00beff" />
        <stop offset="0.51" stopColor="#00d2ff" />
        <stop offset="0.76" stopColor="#00dfff" />
        <stop offset="1" stopColor="#00e3ff" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="43.83"
        x2="19.64"
        y1="172"
        y2="172"
        gradientTransform="matrix(1 0 0 -1 0 202)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffe000" />
        <stop offset="0.41" stopColor="#ffbd00" />
        <stop offset="0.78" stopColor="orange" />
        <stop offset="1" stopColor="#ff9c00" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="34.83"
        x2="12.07"
        y1="169.7"
        y2="146.95"
        gradientTransform="matrix(1 0 0 -1 0 202)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ff3a44" />
        <stop offset="1" stopColor="#c31162" />
      </linearGradient>
      <linearGradient
        id="d"
        x1="17.3"
        x2="27.46"
        y1="191.82"
        y2="181.66"
        gradientTransform="matrix(1 0 0 -1 0 202)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#32a071" />
        <stop offset="0.07" stopColor="#2da771" />
        <stop offset="0.48" stopColor="#15cf74" />
        <stop offset="0.8" stopColor="#06e775" />
        <stop offset="1" stopColor="#00f076" />
      </linearGradient>
    </defs>
    <path fill="none" d="M0 0h155v60H0z" />
    <rect width="135" height="40" x="10" y="10" rx="5" ry="5" />
    <path
      fill="#a6a6a6"
      d="M140 10.8a4.2 4.2 0 014.2 4.2v30a4.2 4.2 0 01-4.2 4.2H15a4.2 4.2 0 01-4.2-4.2V15a4.2 4.2 0 014.2-4.2h125m0-.8H15a5 5 0 00-5 5v30a5 5 0 005 5h125a5 5 0 005-5V15a5 5 0 00-5-5z"
    />
    <path
      fill="#fff"
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="0.2"
      d="M57.42 20.24a2.71 2.71 0 01-.75 2 2.91 2.91 0 01-2.2.89 3.15 3.15 0 01-2.21-5.37 3 3 0 012.21-.9 3.1 3.1 0 011.23.25 2.47 2.47 0 01.94.67l-.53.53a2 2 0 00-1.64-.71 2.32 2.32 0 00-2.33 2.4 2.36 2.36 0 004 1.73 1.89 1.89 0 00.5-1.22h-2.17v-.72h2.91a2.54 2.54 0 01.04.45zm4.58-2.5h-2.7v1.9h2.46v.72H59.3v1.9H62V23h-3.5v-6H62zM65.28 23h-.77v-5.26h-1.68V17H67v.74h-1.72zm4.66 0v-6h.77v6zm4.19 0h-.77v-5.26h-1.68V17h4.12v.74h-1.67zm9.48-.78a3.12 3.12 0 01-4.4 0 3.24 3.24 0 010-4.45 3.1 3.1 0 014.4 0 3.23 3.23 0 010 4.45zm-3.83-.5a2.31 2.31 0 003.26 0 2.56 2.56 0 000-3.44 2.31 2.31 0 00-3.26 0 2.56 2.56 0 000 3.44zm5.8 1.28v-6h.94l2.92 4.67V17h.77v6h-.8l-3.05-4.89V23z"
    />
    <path
      fill="#fff"
      d="M78.14 31.75A4.25 4.25 0 1082.41 36a4.19 4.19 0 00-4.27-4.25zm0 6.83a2.58 2.58 0 112.4-2.58 2.46 2.46 0 01-2.4 2.58zm-9.31-6.83A4.25 4.25 0 1073.09 36a4.19 4.19 0 00-4.27-4.25zm0 6.83A2.58 2.58 0 1171.22 36a2.46 2.46 0 01-2.4 2.58zm-11.09-5.52v1.8h4.32a3.77 3.77 0 01-1 2.27 4.42 4.42 0 01-3.33 1.32 4.8 4.8 0 010-9.6A4.6 4.6 0 0161 30.14l1.27-1.27A6.29 6.29 0 0057.74 27a6.61 6.61 0 100 13.21 6 6 0 004.61-1.85 6 6 0 001.56-4.22 5.87 5.87 0 00-.1-1.13zm45.31 1.4a4 4 0 00-3.64-2.71 4 4 0 00-4 4.25 4.16 4.16 0 004.22 4.25 4.23 4.23 0 003.54-1.88l-1.45-1a2.43 2.43 0 01-2.09 1.18 2.16 2.16 0 01-2.06-1.29l5.69-2.35zm-5.8 1.42a2.33 2.33 0 012.22-2.48 1.65 1.65 0 011.58.9zM92.63 40h1.87V27.5h-1.87zm-3.06-7.3h-.07a3 3 0 00-2.24-1 4.26 4.26 0 000 8.51 2.9 2.9 0 002.24-1h.06v.61c0 1.63-.87 2.5-2.27 2.5a2.35 2.35 0 01-2.14-1.51l-1.63.68A4.05 4.05 0 0087.29 44c2.19 0 4-1.29 4-4.43V32h-1.72zm-2.14 5.88a2.59 2.59 0 010-5.16A2.4 2.4 0 0189.7 36a2.38 2.38 0 01-2.28 2.58zm24.38-11.08h-4.47V40h1.87v-4.74h2.61a3.89 3.89 0 100-7.76zm0 6h-2.61v-4.26h2.65a2.14 2.14 0 110 4.29zm11.53-1.8a3.5 3.5 0 00-3.33 1.91l1.66.69a1.77 1.77 0 011.7-.92 1.8 1.8 0 012 1.61v.13a4.13 4.13 0 00-1.95-.48c-1.79 0-3.6 1-3.6 2.81a2.89 2.89 0 003.1 2.75 2.63 2.63 0 002.4-1.2h.06v1h1.8v-4.81c0-2.19-1.66-3.46-3.79-3.46zm-.23 6.85c-.61 0-1.46-.31-1.46-1.06 0-1 1.06-1.33 2-1.33a3.32 3.32 0 011.7.42 2.26 2.26 0 01-2.19 2zM133.74 32l-2.14 5.42h-.06L129.32 32h-2l3.33 7.58-1.9 4.21h1.95L135.82 32zm-16.81 8h1.87V27.5h-1.87z"
    />
    <path fill="url(#a)" d="M20.44 17.54a2 2 0 00-.46 1.4v22.12a2 2 0 00.46 1.4l.07.07L32.9 30.15v-.29L20.51 17.47z" />
    <path fill="url(#b)" d="M37 34.28l-4.1-4.13v-.29l4.1-4.14.09.05L42 28.56c1.4.79 1.4 2.09 0 2.89l-4.89 2.78z" />
    <path fill="url(#c)" d="M37.12 34.22L32.9 30 20.44 42.46a1.63 1.63 0 002.08.06l14.61-8.3" />
    <path fill="url(#d)" d="M37.12 25.78l-14.61-8.3a1.63 1.63 0 00-2.08.06L32.9 30z" />
    <path
      style={{ isolation: 'isolate' }}
      d="M37 34.13l-14.49 8.25a1.67 1.67 0 01-2 0l-.07.07.07.07a1.66 1.66 0 002 0l14.61-8.3z"
      opacity="0.2"
    />
    <path
      style={{ isolation: 'isolate' }}
      d="M20.44 42.32a2 2 0 01-.46-1.4v.15a2 2 0 00.46 1.4l.07-.07zM42 31.3l-5 2.83.09.09L42 31.44A1.75 1.75 0 0043 30a1.86 1.86 0 01-1 1.3z"
      opacity="0.12"
    />
    <path
      fill="#fff"
      style={{ isolation: 'isolate' }}
      d="M22.51 17.62L42 28.7a1.86 1.86 0 011 1.3 1.75 1.75 0 00-1-1.44L22.51 17.48c-1.4-.79-2.54-.13-2.54 1.47v.15c.03-1.61 1.15-2.27 2.54-1.48z"
      opacity="0.25"
    />
  </svg>
)

export default AppStoreGoogle
