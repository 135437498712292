import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background-color: ${props => props.theme.medium};
  color: ${props => props.theme.white};
  transition: all 0.3s;

  :hover,
  :focus {
    background-color: ${props => props.theme.secondary};
    transition: all 0.3s;
  }

  :active {
    background-color: ${props => props.theme.primary};
    transition: all 0.3s;
  }
`
const PrimaryButton = props => <Button {...props}>{props.title}</Button>

export default PrimaryButton
