import React from 'react'
import { Logo } from '../Images'
import Container from './HeaderContainer'
import Nav from './HeaderNav'

const MainHeader = () => {
  return (
    <Container>
      <Logo />
      <Nav />
    </Container>
  )
}

export default MainHeader
