import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  outline: 0;
  background-color: ${props => props.theme.white};
  border: none;
  padding: 1.5em 1em;
  text-align: center;
  color: ${props => props.theme.primary};
  font-size: 0.75em;
  font-weight: 600;
  margin: 0.5em 0;
  transition: all 0.3s;
  :hover {
    background: ${props => props.theme.primary};
    color: ${props => props.theme.white};
    transition: all 0.3s;
  }
  @media screen and (min-width: 1000px) {
    margin-right: 1em;
    padding: 1.5em 2em;
    white-space: nowrap;
  }
`

const PrivacyButton = ({ onClick, title, name }) => (
  <Button name={name} onClick={onClick}>
    {title}
  </Button>
)

export default PrivacyButton
