import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Container from './WidgetContainer'

const Box = styled(Container)`
  text-align: center;
`
const NavLink = styled(Link)`
  display: block;
  color: ${props => props.theme.white};
  width: 100%;
  height: 100%;
  text-decoration: none;
  transition: 0.3s;
  padding: 0.5em 0;
  :hover {
    transition: 0.3s;
    color: ${props => props.theme.highlight};
  }
`

const Wrapper = styled.div`
  margin: 1em 0;
`

const Navigation = () => (
  <Box girdArea="navigation">
    <h5>Pages</h5>
    <Wrapper>
      <NavLink to="/">Home</NavLink>
      <NavLink to="/about">About</NavLink>
      <NavLink to="/services">Services</NavLink>
      <NavLink to="/contact">Contact</NavLink>
      <NavLink to="/">News</NavLink>
      <NavLink to="/">Careers</NavLink>
      <NavLink to="/privacy">Privacy</NavLink>
    </Wrapper>
  </Box>
)

export default Navigation
