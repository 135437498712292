import React from 'react'
import styled from 'styled-components'
import Container from './WidgetContainer'

const Telephone = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: calc(16px + (18 - 16) * (100vw - 400px) / (1000 - 400));
  line-height: 1.8;
  margin: 1em o;
`

const Box = styled(Container)`
  text-align: center;
  width: 100%;
  align-items: center;
`
const Contact = () => (
  <Box gridArea="contact">
    <h5>Contact</h5>
    <p>Registered Office</p>
    <p>
      Unit 1 Barons Court, <br />
      Graceways, <br />
      Blackpool <br />
      FY4 5GP
    </p>
    <Telephone href="tel:+448003689329">T: 0800 368 9329</Telephone>
  </Box>
)

export default Contact
