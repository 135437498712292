import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

const color = props => props.theme.medium

const RadioGroup = styled.div`
  padding: 1em;
    label {
      display: block;
      padding: .5em;
      cursor: pointer;
      font-size: .75em;
      color: ${props => props.theme.slate};
      letter-spacing: 2px;
      text-transform: uppercase;
      font-weight: 700;
      transition: all .3s;
    }

    label > span:before {
      content: "";
      display: inline-block;
      vertical-align: -.25em;
      height: 1em;
      width: 1em;
      border-radius: 50%;
      border: 2px solid rgba(0, 0, 0, 0.5);
      margin-right: .5em;
    }

    label > input:checked + span:before {
      border-color: ${props => props.theme.medium};
      background-image: radial-gradient(circle closest-side, ${color} 0%, ${color} 50%, transparent 50%, transparent 100%);
    }
    label > input:checked + span {
      color: ${props => props.theme.medium};
    }

    label > input:disabled + span {
      opacity: .5;
      cursor: not-allowed;
    }

    label > input:disabled:checked + span:before {
      background-image: radial-gradient(circle closest-side, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 50%, transparent 50%, transparent 100%);
    }

    label > input {
      display: none;
    }


`

class RadioButton extends Component {
  constructor(props) {
    super(props);

    this.state = { value: this.props.value };
  }

  onChange(e) {
    let value = e.target.value;

    this.setState({ value: value }, () => typeof this.props.onUpdate === "function" ? this.props.onUpdate(value) : null);
  }

  get value() {
    return this.state.value;
  }

  render() {
    return (
      <RadioGroup {...this.props}>
        {this.props.items.map(item => {
          return (
            <label key={item.value}>
              <input
                type="radio"
                checked={this.state.value === item.value}
                disabled={item.disabled}
                value={item.value}
                name={this.props.name}
                onChange={this.onChange.bind(this)}
              />
              <span>{item.label}</span>
            </label>
          );
        })}
      </RadioGroup>
    );
  }
}

// RadioButton.propTypes = {
//   name: React.PropTypes.string.isRequired,
//   value: React.PropTypes.string.isRequired,
//   items: React.PropTypes.arrayOf(React.PropTypes.shape({
//     value: React.PropTypes.string.isRequired,
//     label: React.PropTypes.string.isRequired,
//     checked: React.PropTypes.bool
//   })).isRequired,
//   onUpdate: React.PropTypes.func
// };

export default RadioButton



