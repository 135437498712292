import React from 'react'
import { ThemeProvider } from 'styled-components'

const defaults = {
  primaryFont: 'Poppins, "Lucida Grande", Tahoma, Sans-Serif',
  white: '#ffffff',
  success: '#8EE2AB',
  successText: '#185C59',
  error: '#E36762',
  errorText: '',
  warning: '#F7E87A',
  warningText: '#E0A011',
  lightGrey: '#e6e6e6',
  slate: '#4A4A4A',
  lightSlate: '#afafaf',
  midSlate: '#6A6A6A',
}
const milaco = {
  primary: '#194188',
  secondary: '#004F96',
  medium: '#2B69D8',
  highlight: '#B5DBFC',
  ...defaults,
}

const dark = {
  primary: '#194188',
  secondary: '#004F96',
  medium: '#2B69D8',
  highlight: '#B5DBFC',
  ...defaults,
}

const Theme = ({ children, theme }) => {
  let currentTheme

  switch (theme) {
    case 'leadership':
      currentTheme = dark
      break
    default:
      currentTheme = milaco
      break
  }

  return (
    <>
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </>
  )
}

export default Theme
