import React from 'react'
import styled, { keyframes } from 'styled-components'
import { MobileIcon } from '../Icons/index'

const Rotate = keyframes`
  0% {
    transform: rotate(90deg);
  }
  20% {
    transform: rotate(90deg);
  }
  30% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
  }
  
`

const Container = styled.div`
  display: none;
  @media only screen and (orientation: landscape) and (max-device-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: ${props => props.theme.primary};
    z-index: 5000;
    overflow: hidden;
  }

  svg {
    animation: ${Rotate} 5s infinite linear;
  }
`

const Message = styled.h6`
  color: ${props => props.theme.white};
  text-align: center;
  font-weight: 600;
  margin: 0 auto;
  display: block;
  margin-top: 1em;
`

export default function RotateMessage() {
  return (
    <Container>
      <MobileIcon width="25px" />
      <Message>Please rotate device to portrait mode</Message>
    </Container>
  )
}
