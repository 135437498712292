import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { HomeIcon, ServicesIcon, InfoIcon, MoreIcon } from '../Icons'
import { Modal } from '../Modals'
import { useModal } from '../Hooks'
import ModalNav from './ModalNav'

const Nav = styled.nav`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

const NavLeft = styled.ul`
  width: calc(50% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
`
const NavRight = styled.ul`
  width: 100%;
  max-width: calc(50% - 40px);
  display: flex;
`

const LinkContainer = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.medium};
    transition: 0.3s;
  }
`
const NavLink = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: 0.3s;
`

const NavText = styled.p`
  margin: 0;
  color: ${props => props.theme.white};
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 1px;
`
const NavLinks = () => {
  const { isOpen, toggle } = useModal()
  return (
    <>
      <Nav>
        <NavLeft>
          <LinkContainer>
            <NavLink to="/">
              <HomeIcon fill="#fff" width="25px" />
              <NavText>Home</NavText>
            </NavLink>
          </LinkContainer>
          <LinkContainer>
            <NavLink to="/about">
              <InfoIcon fill="#fff" width="25px" />
              <NavText>About</NavText>
            </NavLink>
          </LinkContainer>
        </NavLeft>
        <NavRight>
          <LinkContainer>
            <NavLink to="/services">
              <ServicesIcon fill="#fff" width="25px" />
              <NavText>Services</NavText>
            </NavLink>
          </LinkContainer>
          <LinkContainer onClick={toggle}>
            <MoreIcon fill="#fff" width="25px" />
            <NavText>More</NavText>
          </LinkContainer>
        </NavRight>
      </Nav>
      {isOpen ? (
        <Modal isOpen={isOpen} hide={toggle}>
          <ModalNav />
        </Modal>
      ) : (
        ''
      )}
    </>
  )
}

export default NavLinks
