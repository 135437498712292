import React from 'react'
import styled from 'styled-components'
import Container from './WidgetContainer'
import { Button } from '../../Form'

const Article = styled.div`
  margin-top: 1em;
`

const Image = styled.div`
  width: 100%;
  background-color: ${props => props.theme.highlight};
  height: 100px;
`

const News = () => (
  <Container girdArea="news">
    <h5>News</h5>
    <Article>
      <h6>New Website</h6>
      <small>Posted: 14th July 2019</small>
      <Image />
      <Button buttonType="primary" title="Read More" />
    </Article>
  </Container>
)

export default News
