import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { TimelineMax, Linear } from 'gsap'
import styled from 'styled-components'

const Nav = styled.nav`
  display: flex;
  visibility: hidden;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  padding: 2em;
  background-color: ${props => props.theme.primary};
`

const Links = styled.ul`
  li {
    width: 100%;
    height: 100%;
    a {
      display: block;
      text-transform: uppercase;
      padding: 1em;
      color: ${props => props.theme.white};
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      transition: 0.3s;
      opacity: 0.5;
      :hover {
        opacity: 1;
        transition: 0.3s;
      }
      &.active {
        opacity: 1;
      }
    }
  }
`

const NavLink = styled.li``
const ModalNav = () => {
  const tl = new TimelineMax()
  useEffect(() => {
    tl.set('.nav', { visibility: 'inherit' })
      .from('.nav', 0.5, {
        height: 0,
        y: '100vh',
      })
      .staggerFrom('.link', 0.2, { opacity: 0, x: 20, ease: Linear.easeInOut }, 0.2)
  }, [tl])
  return (
    <Nav className="nav">
      <Links className="link">
        <NavLink>
          <Link to="/" activeClassName="active">
            Home
          </Link>
        </NavLink>
      </Links>
      <Links className="link">
        <NavLink>
          <Link to="/about" activeClassName="active">
            About
          </Link>
        </NavLink>
      </Links>
      <Links className="link">
        <NavLink>
          <Link to="/services" activeClassName="active">
            Services
          </Link>
        </NavLink>
      </Links>
      <Links className="link">
        <NavLink>
          <Link to="/contact" activeClassName="active">
            Contact
          </Link>
        </NavLink>
      </Links>
      {/* <Links>
        <NavLink className="link">
          <Link to="/news" activeClassName="active">
            News
          </Link>
        </NavLink>
      </Links>
      <Links className="link">
        <NavLink>
          <Link to="/careers" activeClassName="active">
            Careers
          </Link>
        </NavLink>
      </Links> */}
      <Links className="link">
        <NavLink>
          <Link to="/privacy" activeClassName="active">
            Privacy
          </Link>
        </NavLink>
      </Links>
    </Nav>
  )
}

export default ModalNav
