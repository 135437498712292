import React from 'react'
import styled from 'styled-components'
import { LogoIcon } from '../../Icons'
import { AndyLogo } from '../../Images'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.theme.secondary};
  padding-top: 1.5em;
  padding-bottom: 120px;
  @media screen and (min-width: 1000px) {
    padding-bottom: 0;
  }
`
const Text = styled.small`
  display: flex;
  margin: 0.5em 0;
  justify-content: center;

  color: ${props => props.theme.white};
  text-align: center;
`

const LogoText = styled(Text)`
  align-items: flex-end;
  svg {
    margin-right: 1ch;
  }
`
const ExternalLink = styled.a`
  text-align: center;
  text-decoration: none;
  display: block;
  cursor: pointer;
  :hover,
  :active,
  :visited,
  :focus {
    text-decoration: none;
  }
`
const SmallPrint = () => {
  const date = new Date()

  return (
    <Wrapper girdArea="smallprint">
      <div>
        <LogoText>
          <LogoIcon height="3ch" fill="#fff" /> &copy; {date.getFullYear()}
        </LogoText>
      </div>
      <div>
        <Text>Registered in England & Wales with Company No. 12101869</Text>
      </div>
      <ExternalLink target="blank" href="https://andythomo.com">
        <Text>
          website designed by <AndyLogo />
        </Text>
      </ExternalLink>
    </Wrapper>
  )
}

export default SmallPrint
