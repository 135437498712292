import React from 'react'
import styled from 'styled-components'

const WidgetContainer = ({ girdArea, children }) => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    grid-area: ${girdArea};
    margin: 1em 0;
    h5 {
      font-weight: 600;
    }
    @media screen and (min-width: 1000px) {
      marign: 0;
    }
  `
  return <Container>{children}</Container>
}

export default WidgetContainer
