import React from 'react'

const Milaco = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 155 32"
    version="1.1"
    viewBox="0 0 155 32"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M3.3.3h12c.8 0 1.4.6 1.4 1.4 0 .3-.1.6-.3.9l-6 7.5c-.5.6-1.4.7-2 .2-.1-.1-.2-.1-.2-.2l-6-7.4c-.4-.7-.3-1.6.3-2.1.2-.2.5-.3.8-.3z" />
    <path d="M26.1 1.4H31.3V30.7H26.1z" />
    <path d="M30.7 2v28.1h-4V2h4M31.9.8h-6.4v30.5h6.4V.8z" />
    <path d="M38.5 30.7L38.5 1.4 43.7 1.4 43.7 25.9 56.4 25.9 56.4 30.7z" />
    <path d="M43.1 2v24.4h12.7v3.7H39.1V2h4M44.3.8h-6.4v30.5H57v-6H44.3V.8z" />
    <path d="M85 30.7L75.4 9.5 66.5 30.7 61.1 30.7 73.7 1.3 77.3 1.3 90.4 30.7z" />
    <path d="M76.9 1.9l12.6 28.2h-4.1l-3.7-8.3-6.3-13.7-9.3 22.1H62L74.1 1.9h2.8m-1.5 6.2l6.2 13.8-6.2-13.8M77.6.7h-4.4l-.2.7-12.1 28.3-.7 1.7h6.7l.3-.7L75.5 11l5.1 11.2 3.8 8.3.3.7h6.7l-.8-1.7L78 1.4l-.4-.7zM138.5 31.1c-4.1.1-8-1.4-11-4.2-2.9-2.8-4.5-6.6-4.4-10.6-.1-4.1 1.5-8 4.4-10.8 3-2.9 7-4.4 11.2-4.3 4.2-.1 8.2 1.4 11.3 4.3 3 2.8 4.6 6.7 4.5 10.8.1 4.1-1.6 8-4.6 10.7-3.1 2.7-7.2 4.2-11.4 4.1zm.3-25c-2.8-.1-5.5.9-7.5 2.8s-3 4.5-2.9 7.3c-.1 2.7 1 5.3 2.9 7.1 4.2 3.8 10.6 3.8 14.8-.1 2-1.9 3.1-4.5 3-7.2.1-2.7-1-5.3-3-7.1-1.9-1.9-4.5-2.9-7.3-2.8z" />
    <path d="M138.7 1.6c4-.1 7.9 1.4 10.8 4.1 2.9 2.7 4.4 6.4 4.3 10.3.1 3.9-1.5 7.7-4.4 10.3-3 2.7-6.9 4.2-11 4.1-3.9.1-7.7-1.4-10.6-4.1-2.8-2.6-4.4-6.4-4.3-10.2-.1-3.9 1.4-7.7 4.3-10.4 3-2.7 6.9-4.2 10.9-4.1m0 25.2c2.9.1 5.8-1 7.9-3.1 2.1-2 3.2-4.8 3.1-7.7.1-2.8-1.1-5.6-3.1-7.5-2.1-2-4.9-3.1-7.7-3-2.9-.1-5.8 1-7.9 3-2.1 2-3.2 4.8-3.1 7.7-.1 2.8 1.1 5.6 3.1 7.6s4.8 3.1 7.7 3m0-26.3C134.4.4 130.2 2 127.1 5c-3.1 2.9-4.7 7-4.6 11.3-.1 4.2 1.6 8.2 4.6 11.1 3.1 2.9 7.2 4.5 11.4 4.4 4.4.1 8.6-1.4 11.9-4.4 3.1-2.9 4.9-6.9 4.8-11.2.1-4.2-1.6-8.3-4.7-11.2-3.3-3.1-7.5-4.7-11.8-4.5zm0 25.1c-2.6.1-5.1-.9-7-2.7-1.8-1.7-2.8-4.2-2.8-6.7-.1-2.6.9-5.1 2.7-6.8 1.9-1.8 4.5-2.8 7.1-2.7 2.6-.1 5.1.9 6.9 2.7 1.8 1.7 2.8 4.1 2.8 6.7.1 2.6-.9 5-2.8 6.8-1.8 1.8-4.4 2.8-6.9 2.7zM.6 16.1v14.8c0 .4.4.6.8.6h16c.4 0 .7-.2.8-.6V16.1c0-.6-.9-.8-1.3-.4L10 22.6c-.2.2-.4.2-.6.2-.2 0-.4-.1-.6-.2l-6.9-7c-.4-.3-1.3-.1-1.3.5zM108.2 30.9c-2.9.1-5.7-.6-8.2-2-4.5-2.7-7.3-7.5-7.3-12.8-.1-4 1.6-7.9 4.5-10.7 3-2.9 7-4.4 11.1-4.3 3.5.1 6.9.9 10 2.3l.4.2v5.7l-.9-.5c-2.8-1.7-6-2.6-9.3-2.7-2.8-.1-5.5.9-7.6 2.8-2 1.8-3.1 4.5-3 7.2-.1 2.7 1 5.3 2.9 7.1 2.1 1.9 4.8 2.9 7.6 2.8 3.4 0 6.8-1.1 9.6-3l.9-.6v5.9l-.3.2c-3.2 1.6-6.8 2.5-10.4 2.4z" />
    <path d="M108.3 1.7c3.4.1 6.7.9 9.8 2.3v4.3c-2.9-1.8-6.2-2.7-9.6-2.8-3-.1-5.8 1-8 3-2.1 2-3.2 4.7-3.2 7.6-.1 2.8 1.1 5.6 3.1 7.6 2.2 2 5 3.1 8 3 3.5 0 7-1.1 9.9-3.1V28c-3.1 1.6-6.6 2.5-10.1 2.4-2.8.1-5.5-.6-7.9-1.9-2.2-1.2-3.9-3-5.2-5.2-1.3-2.2-1.9-4.6-1.9-7.1-.1-3.9 1.5-7.6 4.3-10.3 2.9-2.8 6.8-4.3 10.8-4.2m0-1.2C104 .4 99.8 2 96.7 5c-3.1 2.9-4.8 6.9-4.7 11.1 0 2.7.7 5.4 2.1 7.7 1.3 2.3 3.3 4.3 5.6 5.6 2.6 1.4 5.6 2.1 8.5 2 3.7.1 7.4-.8 10.7-2.5l.6-.3v-7.3l-1.8 1.2c-2.7 1.9-6 2.9-9.3 2.9-2.6.1-5.2-.8-7.2-2.6-3.7-3.7-3.7-9.7.1-13.4 1.9-1.8 4.5-2.8 7.1-2.7 3.2.1 6.3 1 9 2.6l1.8 1V3.2l-.7-.3c-3.2-1.5-6.7-2.3-10.2-2.4z" />
  </svg>
)

export default Milaco
