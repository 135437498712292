import React from 'react'
import styled from 'styled-components'
import NavLinks from './NavLinks'
import PhoneButton from './PhoneButton'

const Nav = styled.nav`
  background-color: ${props => props.theme.secondary};
  position: fixed;
  bottom: 0;
  height: 80px;
  width: 100%;
  z-index: 1000;
  @media screen and (min-width: 1000px) {
    display: none;
  }
`

const NavBar = () => {
  return (
    <Nav>
      <PhoneButton />
      <NavLinks />
    </Nav>
  )
}

export default NavBar
