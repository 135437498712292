import React from 'react'
import styled from 'styled-components'

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => props.checked ? props.theme.highlight : props.theme.grey};
  border-radius: 3px;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${props => props.theme.highlight};
  }
  ${Icon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'}
  }
`
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`
const Confirm = styled.span`
  display: inline;
  margin-left: 8px;
  font-size: .8em;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
`


const CheckBox = (props) => {
  return (
    <label>
      <CheckboxContainer>
        <HiddenCheckbox type={props.type} checked={props.checked} onChange={props.onChange} />
        <StyledCheckbox checked={props.checked} onChange={props.onChange} {...props}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
      <Confirm>
        {props.confirmTitle}
      </Confirm>
    </label>
  )
}

export default CheckBox
