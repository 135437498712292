import React from 'react'
import styled from 'styled-components'
import { LinkedinLogo } from '../../Icons'
import Container from './WidgetContainer'

const ExternalLink = styled.a`
  text-align: center;
  text-decoration: none;
  display: block;
  cursor: pointer;
  @media screen and (min-width: 1000px) {
    text-align: right;
  }
  :hover,
  :active,
  :visited,
  :focus {
    text-decoration: none;
  }
`
const SmallPrint = () => (
  <Container girdArea="social">
    <ExternalLink target="blank" aria-label="Follow Milaco on Linkedin" href="https://www.linkedin.com/company/milaco/">
      <LinkedinLogo width="150px" />
    </ExternalLink>
  </Container>
)

export default SmallPrint
