import React from 'react'
import styled from 'styled-components'
import { LeftIcon } from '../../Icons'

const Button = styled.button`
  display: flex;
  width: initial;
  background: none;
  padding: 0;
  border: none;
  box-shadow: none;
  color: ${props => props.theme.primary};
  transition: all .3s;
  svg {
    margin-right: .8em;
    transition: all .3s;
  }

  
  :hover,
  :focus {
    color: ${props => props.theme.highlight};
    background: none;
    border: none;
    box-shadow: none;
    transition: all .3s;
    svg {
      fill: ${props => props.theme.highlight};
      transition: all .3s;
    }
  }

  :active {
    color: ${props => props.theme.primary};
    transition: all .3s;
    svg {
      fill: ${props => props.theme.primary};
      transition: all .3s;
    }
  }
`
const PreviousButton = (props) => {
  return (
    <Button {...props}><LeftIcon fill={`${props => props.theme.primary}`} width={'12px'}/>{props.title}</Button>
  )
}

export default PreviousButton
