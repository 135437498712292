import React from 'react'

const CallExpert = props => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 710 542.38" {...props}>
    <path
      d="M224 302a80.12 80.12 0 01-78.38-64h-48c8 63.06 61.17 112 126.39 112s118.44-48.94 126.39-112h-48a80.13 80.13 0 01-78.4 64zm89.6 80c-28.72 0-42.45 16-89.6 16s-60.88-16-89.56-16A134.4 134.4 0 000 516.36 25.6 25.6 0 0025.6 542h396.8a25.6 25.6 0 0025.6-25.6A134.4 134.4 0 00313.6 382zM50.94 494a86.57 86.57 0 0183.5-64c14.44 0 38.28 16 89.56 16 51.47 0 75.1-16 89.6-16a86.55 86.55 0 0183.46 64zM88 206h272a8 8 0 008-8v-32a8 8 0 00-8-8h-8c0-46.41-28.53-85.54-68.79-102.42L256 110V46a16 16 0 00-16-16h-32a16 16 0 00-16 16v64l-27.21-54.42C124.53 72.46 96 111.59 96 158h-8a8 8 0 00-8 8v32a8 8 0 008 8zM596.18 82.4a6.29 6.29 0 00-8.89-.06l-55.42 55-24.06-24.25a6.31 6.31 0 00-8.89-.06l-14.85 14.73a6.29 6.29 0 00-.06 8.89l43.23 43.54a6.29 6.29 0 008.88.07l74.79-74.15a6.31 6.31 0 00.06-8.89L596.18 82.4zM547.49-.38C457.73-.38 385 58.72 385 131.66c0 30.22 12.63 57.9 33.58 80.18-9.46 25-29.14 46.21-29.46 46.47A15.24 15.24 0 00400.21 284c39 0 69.83-16.32 88.3-29.4a196 196 0 0059 9.08c89.76 0 162.51-59.1 162.51-132S637.25-.38 547.49-.38zm0 233.61a164.91 164.91 0 01-49.77-7.68L483.31 221l-12.38 8.76a136.76 136.76 0 01-36.5 18.41 159.6 159.6 0 0012.63-25.52l6.73-17.84L440.71 191c-11.49-12.25-25.26-32.5-25.26-59.29 0-56 59.22-101.57 132-101.57s132 45.58 132 101.57-59.15 101.52-131.96 101.52z"
      className="cls-1"
      transform="translate(0 .38)"
    />
  </svg>
)

export default CallExpert
