import React, { Component } from 'react'
import styled from 'styled-components'

const Select = styled.select`
  font-family: ${props => props.theme.lato};
  width: 100%;
  padding: 1.5em;
  outline: none;
  font-family: ${props => props.theme.lato};
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: .85em;
  border: 2px solid ${props => props.theme.grey};
    &:focus {
      border: 3px solid ${props => props.theme.highlight};
    }
`

const Option = styled.option`
  padding: 2em;
  ::after {
    content: '';
    background-color: red;
  }
`

class SelectMenu extends Component {
  constructor() {
    super()
    this.state = {

    }
  }
  render() {
    return (
      <Select>
        <Option defaultValue disabled>Choose one</Option>
        <Option>Employer</Option>
        <Option>Job Seeker</Option>
      </Select>
    )
  }
}

export default SelectMenu