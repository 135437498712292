import React from "react";
import styled from 'styled-components';
import { between } from 'polished'

const TextAreaContainer = styled.div`
  margin: 1.5em 0;
  textarea {
  outline: none;
  border: none;
  font-family: ${props => props.theme.lato};;
  font-size: 1em;
  letter-spacing: 1px;
  color: ${props => props.theme.slate};
  border-bottom: 2px solid ${props => props.theme.grey};
  width: 100%;
  padding: 1em .5em;
  background: #f7f7f7;
  transition: background .3s;
    /* @media screen and (min-width: 36em) {
      max-width: 30em;
    } */

    :focus {
      background: #fcfcfc;
      transition: background .3s;
      border-bottom: 2px solid ${props => props.theme.highlight};
    }

     ::placeholder {
      font-size: .75em;
      text-transform: uppercase;
      letter-spacing: 4px;
    }
  }

  label {
    font-size: .75em;
    line-height: ${between("1.7em", "1.7em", "20em", "120em")};
    color: ${props => props.theme.slate};
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 700;
 }
`

const TextArea = props => (
  <TextAreaContainer>
    <label>{props.label}</label>
    <textarea
      className="form-control"
      name={props.name}
      rows={props.rows}
      cols={props.cols}
      value={props.value}
      onChange={props.handleChange}
      placeholder={props.placeholder}
    />
  </TextAreaContainer>
);

export default TextArea;
