import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
`

const Label = styled.label`
  font-size: 0.75em;
  line-height: 1.7em;
  color: ${props => props.theme.slate};
  font-weight: 600;
`

const StyledInput = styled.input`
  background-color: ${props => props.bgColor || props.theme.lightGrey};
  box-sizing: border-box;
  padding-top: 1em;
  outline: none;
  border: 2px solid ${props => props.theme.lightGrey};
  font-size: 1em;
  letter-spacing: 1px;
  color: ${props => props.theme.slate};
  width: 100%;
  margin-top: 0.5em;
  -webkit-appearence: none;
  transition: 0.3s;
  /* :focus {
    border: 2px solid ${props => props.theme.highlight};
    transition: border 0.3s;
  }

  :focus:invalid {
    color: ${props => props.theme.error};
    border: 2px solid ${props => props.theme.error};
    transition: border 0.3s;
  }

  :valid {
    color: ${props => props.theme.success};
    border: 2px solid ${props => props.theme.success};
    transition: border 0.3s;
  }

  :valid + ${Label} {
    color: ${props => props.theme.success};
  }

  :focus:invalid + ${Label} {
    color: ${props => props.theme.error};
  } */
`

const ErrorText = styled.span`
  margin-bottom: 1em;
  line-height: 1.8;
  letter-spacing: 3px;
  padding-bottom: 10px;
  font-size: 0.75em;
  color: ${props => props.theme.error};
  display: inline-block;
`
const Input = props => (
  <Container>
    {props.message ? (
      <ErrorText>
        <strong>{props.label}</strong>: {props.message}
      </ErrorText>
    ) : (
      ''
    )}
    <StyledInput
      id={props.name}
      name={props.name}
      type={props.type}
      value={props.value}
      onChange={props.onChange}
      onFocus={props.onFocus}
      placeholder={props.placeholder}
      required={props.required}
      autoComplete={props.autoComplete}
      noValidate={props.noValidate}
    />
    {props.noLabel ? (
      ''
    ) : (
      <Label htmlFor={props.name}>
        {props.label}
        {props.required ? '*' : ''}
      </Label>
    )}
  </Container>
)

export default Input
