import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100%;
`
const Container = ({ children }) => <Wrapper>{children}</Wrapper>

export default Container
