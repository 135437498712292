import React from 'react'
import styled from 'styled-components'
import { News, Contact, Navigation, MailingList, SmallPrint, Social } from './Widgets'
import { AppBanner } from '../Banners'

const FooterContainer = styled.footer`
  color: ${props => props.theme.white};
  width: 100%;
  background-color: ${props => props.theme.primary};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 2em 0;
  max-width: 1400px;
  @media screen and (min-width: 1000px) {
    display: grid;
    display: -ms-grid;
    width: 90%;
    padding: 3em 0 0 0;
    grid-template-areas:
      'contact contact navigation'
      'signup signup signup'
      '. . social';
    justify-content: space-evenly;
  }
`

const Footer = () => (
  <>
    <AppBanner />
    <FooterContainer>
      <Container>
        <Contact />
        <Navigation />

        <Social />
      </Container>
      <SmallPrint />
    </FooterContainer>
  </>
)

export default Footer
