import { createGlobalStyle } from 'styled-components'
import 'typeface-poppins'

export const GlobalStyles = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
html, body, #root {
  height: 100% !important;
}


ol, ul {
	list-style: none;
}


blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


/*-- Base Styles --*/
* {
  box-sizing: border-box;
}

html {
  font-family: Poppins, "Lucida Grande", Tahoma, Sans-Serif;
  font-size: 16px;
}

body {
  background-color: ${props => props.theme.lightGrey};
  color: ${props => props.theme.slate};
}

section {
  line-height: 1.8;
  margin: calc( 24px + (40 - 24)*(100vw - 325px)/(2000 - 325)) 0;
}

strong {
  font-weight: 600;
}

h1, h2, h3 {
  color: ${props => props.theme.midSlate};
}

h1 {
  font-size: calc( 39.81px + (67.34 - 39.81)*(100vw - 325px)/(2000 - 325));
  font-weight: 600;
  line-height: calc( 39.81px + (67.34 - 39.81)*(100vw - 325px)/(2000 - 325));
}

h2 {
  font-size: calc( 33.18px + (50.52 - 33.18)*(100vw - 325px)/(2000 - 325));
  line-height: calc( 33.18px + (50.52 - 33.18)*(100vw - 325px)/(2000 - 325));
  letter-spacing: 0.025px;
  font-weight: 400;
}

h3 {
  font-size: calc( 27.65px + (37.90 - 27.65)*(100vw - 325px)/(2000 - 325));
  line-height: calc( 27.65px + (37.90 - 27.65)*(100vw - 325px)/(2000 - 325));
  font-weight: 300;
}

h4 {
  font-size: calc( 23.04px + (28.43 - 23.04)*(100vw - 325px)/(2000 - 325));
  line-height: calc( 23.04px + (28.43 - 23.04)*(100vw - 325px)/(2000 - 325));
  font-weight: 700;
  text-transform: uppercase;
}

h5 {
  font-size: calc( 19.20px + (21.33 - 19.20)*(100vw - 325px)/(2000 - 325));
  line-height: calc( 19.20px + (21.33 - 19.20)*(100vw - 325px)/(2000 - 325));
  font-weight: 600;
}

h6 {
  font-size: calc( 16px + (18 - 16)*(100vw - 325px)/(2000 - 325));
  line-height: calc( 16px + (18 - 16)*(100vw - 325px)/(2000 - 325));
  font-weight: 300;
}

small {
  font-size: calc( 9.6px + (11 - 9.6)*(100vw - 325px)/(2000 - 325));
}

strong {
  font-weight: 600;
}

p {
  font-family: Poppins, "Lucida Grande", Tahoma, Sans-Serif;
  font-size: calc( 16px + (18 - 16)*(100vw - 325px)/(2000 - 325));
  line-height: 1.8;
  max-width: 65ch;
  margin: 1em 0;

  span {
    letter-spacing: 3px;
  }

  a {
    color: #333;
     font-style: italic;
     cursor: pointer;
     font-weight: bold;

     :hover {
       color: #7f7f7f;
     }
  }
}

span {
  text-transform: uppercase;
}
  button, input, select, option, textarea {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 0;
  }
  button {
    display: block;
    color: ${props => props.theme.white};
    box-shadow: 0px 12px 6px -6px rgba(0,0,0,0.24);
    background: ${props => props.theme.medium};
    font-family: ${props => props.theme.primaryFont};
    text-transform: uppercase;
    width: 100%;
    font-size: 1em;
    padding: 1em calc( 32px + (40 - 32)*(100vw - 325px)/(2000 - 325));
    font-weight: 600;
    cursor: pointer;
    margin: 1em 0;
    border: none;
    border-radius: 50px;
    outline: none;
    transform: translateY(0);
    background-position: center;
    transition: all .3s;
    @media screen and (min-width: 1000px) {
      /* display: inline-block; */
      width: initial;
      /* margin-right: calc( 16px + (24 - 16)*(100vw - 325px)/(2000 - 325)); */
      /* padding: 1.5em 2.5em; */
    }

    :hover,
    :focus {
      transform: translateY(-2px);
      transition: all .3s;
    }

    :hover {
      background: ${props => props.theme.secondary};
      border-color: ${props => props.theme.secondary};
      transition: all .3s;
    }

    :active {
      outline: none;
      background: ${props => props.theme.primary};
      border-color: ${props => props.theme.primary};
      background-size: 100%;
      transition: all .3s;
      box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.51);
    }


    :disabled {
      background: #cdcdcd;
      border-color: #cdcdcd;
      color: rgba(0, 0, 0, .25);
      cursor: not-allowed;
      box-shadow: none;
      transform: translateY(0);
      :hover {

        transform: translateY(0);
        border-color: #cdcdcd
      }
    }
  }

  select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  }

  select::-ms-expand {
      display: none;
  }

  [type="text"], [type="url"], [type="tel"], [type="email"], [type="password"], textarea {
    font-family: ${props => props.theme.primaryFont};
    font-size: 16px;
    display: block;
    outline: none;
    width: 100%;
    padding-bottom: .5em;
    border: none;
    color: ${props => props.theme.slate};
    padding: 1em 1.5em;
    border: 2.5px solid  #e8e8e8;
    border-radius: 50px;
    background-color: #e8e8e8;
    -webkit-appearance: none;
    transition: border .3s;
    ::placeholder {
      color: #ACACAC;
      font-weight: 600;
    }
    :focus {
      border: 2.5px solid ${props => props.theme.highlight};
      transition: border .3s;
    }
    @media screen and (min-width: 769px) {
      width: initial;
      min-width: 30%;
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: #e6e6e6;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }

  [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  [type="file"] + label {
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    color: ${props => props.theme.white};
    box-shadow: 0px 12px 6px -6px rgba(0,0,0,0.24);
    background: ${props => props.theme.primary};
    font-family: Lato, "Lucida Grande", Tahoma, Sans-Serif;
    text-transform: uppercase;
    width: 100%;
    font-size: 1em;
    letter-spacing: 3px;
    padding: 1.2em calc( 24px + (40 - 24)*(100vw - 325px)/(2000 - 325));
    font-weight: bold;
    cursor: pointer;
    margin: 0 0 1em 0;
    border: 5px solid ${props => props.theme.primary};
    outline: none;
    transform: translateY(0);
    background-position: center;
    transition: all .3s;

    svg {
      margin-right: .8em;
      fill: ${props => props.theme.white};
    }
      @media screen and (min-width: 769px) {
        display: inline-flexbox;
        width: initial;
        margin-right: calc( 16px + (24 - 16)*(100vw - 325px)/(2000 - 325));
        /* padding: 1.5em 2.5em; */
      }

      :hover,
      :focus {
        transform: translateY(-2px);
        transition: all .3s;
        border-color: ${props => props.theme.medium};
        background: ${props => props.theme.medium};
        }


      :active {
        outline: none;
        border-color: ${props => props.theme.primary};
        background-color: ${props => props.theme.primary};
        background-size: 100%;
        transition: all .3s;
        box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.51);
      }

      :focus {
        transform: translateY(-2px);
        transition: all .3s;
        border-color: #e6e6e6;
      }

      :disabled {
        color: rgba(0, 0, 0, .25);
        cursor: not-allowed;
        box-shadow: none;
        transform: translateY(0);
        :hover {
          background: #cdcdcd;
          transform: translateY(0);
          border-color: #cdcdcd
        }
      }
    }

    .siq_bR {
      display: none; 
      @media screen and (min-width: 1000px) {
        display: block;
        right: 2%;
        bottom: 2%;
      }
    }
`
