import { useState, useEffect } from 'react'

const useLocalStorage = localItem => {
  const [value, setValue] = useState(undefined)

  useEffect(() => {
    setValue(localStorage.getItem(localItem))
  }, [localItem])

  const setLocalStorage = newItem => {
    localStorage.setItem(localItem, newItem)
    setValue(newItem)
  }
  return [value, setLocalStorage]
}

export default useLocalStorage
