import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Image = styled(Img)`
  width: calc(80px + (100 - 80) * (100vw - 400px) / (1000 - 400));
  max-width: 100px;
`

const Logo = ({ alt }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Image alt={alt} fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default Logo
