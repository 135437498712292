import React from 'react'
import styled from 'styled-components'
import { CallExpert } from '../Icons'

const Container = styled.div`
  display: flex;
  padding: 3em 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.color || props.theme.medium};
  color: ${props => props.theme.white};
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  h1 {
    margin: 0.2em 0;
    text-align: center;
    color: ${props => props.theme.white};
  }
  svg {
    margin-bottom: 1em;
    max-width: 300px;
    @media screen and (min-width: 1200px) {
      margin-left: calc(300px - 137.34px);
    }
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.white};
  }
`
const CallBanner = ({ color }) => (
  <Container color={color}>
    <CallExpert fill="#fff" width="50%" />
    <h1>Not sure what you need?</h1>
    <p>Are engineers are free to call anytime…</p>
    <h1>
      <a href="tel:0800 368 9329">0800 368 9329</a>
    </h1>
  </Container>
)

export default CallBanner
