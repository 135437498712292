import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  box-sizing: border-box;
  background: ${props => props.theme.medium};
  border: 5px solid ${props => props.theme.medium};
  color: ${props => props.theme.white};
  transition: all 0.3s;

  :hover,
  :focus {
    background: ${props => props.theme.highlight};
    border: 5px solid ${props => props.theme.highlight};
    color: ${props => props.theme.slate};
    transition: all 0.3s;
  }

  :active {
    background-color: ${props => props.theme.primary};
    border-color: ${props => props.theme.primary};
    color: ${props => props.theme.white};
    transition: all 0.3s;
  }
`
const SecondaryButton = props => <Button {...props}>{props.title}</Button>

export default SecondaryButton
