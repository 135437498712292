import React, { Component } from 'react'
import styled from 'styled-components';
import { FileIcon, WordIcon, PDFIcon, CloudUpload } from '../Icons/index'

const Container = styled.div`
  display: block;
  @media screen and (min-width: 769px) {
    display: inline-block;
  }
`
const FileSpan = styled.span`
  display: inline-flexbox;
  font-size: .75em;
  letter-spacing: 1px;
  margin-bottom: 2em;
  svg {
    margin-right: 5px;
  }
`
class FileUpload extends Component {
   constructor(props) {
    super(props)
    this.state = {
       fileName: '',
       fileExtension: '',
    };
  }

  onChange = e => {

    switch (e.target.name) {
      // Updated this
      case this.props.name :
        if(e.target.files.length > 0) {
            // Accessed .name from file 
            this.setState({ fileName: e.target.files[0].name });
            this.setState({ fileExtension: e.target.files[0].type });
        }
      break;
      default:
        this.setState({ [e.target.name]: e.target.value });
     }
  };
  render() {
    const { fileName } = this.state;
    let file = null;

    const { fileExtension } = this.state;
    let extension = null;

    extension = 
      fileExtension === 'application/pdf' ? (<PDFIcon width={'1em'} fill='#ff0000'/>) : 
      fileExtension === 'application/msword' || 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (<WordIcon width={'1em'} fill='#4063a3'/>) : fileExtension ? (<FileIcon width={'1em'}/>) : ('')
   
      file = fileName 
      ? ( <FileSpan>{extension}{fileName}</FileSpan>) 
      : ( <FileSpan>Choose a file...</FileSpan> );
    return (
      <Container>
        <input type="file" id={this.props.name} name={this.props.name} onChange={ (event) => this.onChange(event)}/>
        <label htmlFor={this.props.name}><CloudUpload width={'25px'} fill={`${props => props.theme.white}`}/>{this.props.title}</label>
       {file}
      </Container>
    )
  }
}


export default FileUpload
