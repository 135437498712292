import React, { useEffect } from 'react'
import styled from 'styled-components'
import { AppStoreApple, AppStoreGoogle } from '../Icons'
import { IPhone } from '../Images'

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`

const Content = styled.div`
  padding-top: 15%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: minmax(200px, auto);
  grid-gap: 1em;
  align-items: center;
  justify-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  color: ${props => props.theme.white};
  @media screen and (min-width: 1000px) {
    width: 90%;
  }
`

const Icons = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: space-around;
  @media screen and (min-width: 1000px) {
    justify-content: start;
  }
`

const Text = styled.div`
  grid-column: 1 / 13;
  text-align: center;
  h3,
  h1 {
    line-height: 1.5;
    color: ${props => props.theme.white};
  }
  h5 {
    margin: 1em 0;
  }
  svg {
    margin-right: 0.5em;
  }
  @media screen and (min-width: 1000px) {
    grid-column: 1 /11;
    text-align: left;
  }
`

const Image = styled.div`
  grid-column: 1 / 13;
  @media screen and (min-width: 1000px) {
    grid-column: 11 / 12;
  }
`

const Circle = styled.div`
  position: absolute;
  opacity: 0.95;
  top: 30%;
  padding: 1em;
  background-color: ${props => props.theme.medium};
  border-radius: 50%;
  height: calc(200vw - 10% - 1em);
  width: calc(200vw - 10% - 1em);
  transform: scale(1);
`

const Circle1 = styled(Circle)`
  background-color: ${props => props.theme.highlight};
  opacity: 0.5;
  z-index: -1;
  right: -50%;
  top: 50%;
  height: 100vw;
  width: 100vw;
  /* @media screen and (min-width: 1000px) {
    height: 100vw;
    width: 100vw;
  } */
`
const Circle2 = styled(Circle)`
  opacity: 0.95;
  z-index: -3;
  right: 0;
  left: -50%;
  top: 10%;
  height: calc(260vw);
  width: calc(260vw);
  /* @media screen and (min-width: 1000px) {
    height: 100vw;
    width: 100vw;
  } */
`
const Circle3 = styled(Circle)`
  opacity: 0.5;
  left: -50%;
  z-index: -2;
  height: calc(150vw);
  width: calc(150vw);
  /* @media screen and (min-width: 1000px) {
    height: 100vw;
    width: 100vw;
  } */
`
const Circle4 = styled(Circle)`
  background-color: ${props => props.theme.primary};
  z-index: -4;
  left: -50%;
  top: 2%;
  height: calc(200vw);
  width: calc(200vw);
  /* @media screen and (min-width: 1000px) {
    height: 100vw;
    width: 100vw;
  } */
`
const AppBanner = () => (
  // const tl = new TweenMax({ repeat: -1, yoyo: true })

  // useEffect(() => {
  //   TweenMax.from('.circle-one', 10, { scale: 0.75, ease: Linear.easeInOut, repeat: -1, yoyo: true })
  //   TweenMax.from('.circle-two', 7, { scale: 0.95, ease: Linear.easeInOut, repeat: -1, yoyo: true })
  //   TweenMax.from('.circle-three', 8, { scale: 1.05, ease: Linear.easeInOut, repeat: -1, yoyo: true })
  //   TweenMax.from('.circle-four', 2, { scale: 0.99, ease: Linear.easeInOut, repeat: -1, yoyo: true })
  // }, [])

  <div>
    <Container>
      <Circle1 className="circle-one" />
      <Circle2 className="circle-two" />
      <Circle3 className="circle-three" />
      <Circle4 className="circle-four" />
      <Content>
        <Text>
          <h1>Your data anywhere...</h1>
          <h3>Inspections are getting an upgrade.</h3>
          <h5>Coming Soon!</h5>
          <Icons>
            <AppStoreApple height="50px" />
            <AppStoreGoogle height="50px" />
          </Icons>
        </Text>
        <Image>
          <IPhone />
        </Image>
      </Content>
    </Container>
  </div>
)

export default AppBanner
