import React from 'react'

export default (props) => {
  return (
   <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 148.3 54' fill={props.fill ? props.fill : '#fff'} width={props.width ? props.width : '100%'}>
    <path className='st0' d='M146.3,47.1c0.4-0.1,0.6-0.3,0.6-0.7c0-0.5-0.3-0.7-0.9-0.7h-1v2.5h0.4v-1.1h0.4l0,0l0.7,1.1h0.4L146.3,47.1 L146.3,47.1z M145.9,46.8h-0.4V46h0.5c0.3,0,0.6,0,0.6,0.4C146.6,46.8,146.3,46.8,145.9,46.8z'
    />
    <path className='st0' d='M146,44.6c-1.3,0-2.4,1-2.4,2.4c0,1.3,1,2.4,2.4,2.4c1.3,0,2.4-1,2.4-2.4C148.3,45.6,147.3,44.6,146,44.6z M146,49c-1.1,0-2-0.9-2-2.1s0.9-2.1,2-2.1s2,0.9,2,2.1S147.1,49,146,49z'
    />
    <polygon className='st0' points='0,23.3 5.6,23.3 5.6,43.6 15.8,43.6 15.8,48.7 0,48.7'
    />
    <path className='st0' d='M18.3,31.5h5.3v17.2h-5.3V31.5z M21,22.9c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1 S19.2,22.9,21,22.9'
    />
    <polygon className='st0' points='45.9,23.3 51.3,23.3 51.3,38.5 57.3,31.5 63.9,31.5 56.9,39.4 63.7,48.7 57,48.7 51.3,40.2 51.3,40.2 51.3,48.7 45.9,48.7'
    />
    <path className='st0' d='M26.6,31.5h5.1v2.3h0.1c0.7-1.4,2.5-2.8,5.1-2.8c5.4,0,6.4,3.6,6.4,8.2v9.4H38v-8.4c0-2,0-4.6-2.8-4.6 S32,37.8,32,40v8.5h-5.3v-17H26.6z'
    />
    <path className='st0' d='M75.1,38.1c0-1.8-1.4-3.3-3.3-3.3c-2.3,0-3.6,1.6-3.8,3.3H75.1z M79.6,45.7c-1.7,2.2-4.5,3.4-7.3,3.4 c-5.3,0-9.6-3.6-9.6-9.1s4.3-9.1,9.6-9.1c5,0,8.1,3.6,8.1,9.1v1.7H68c0.4,2,2,3.3,4,3.3c1.7,0,2.9-0.9,3.8-2.1L79.6,45.7z'
    />
    <path className='st0' d='M91.5,35.6c-2.7,0-4.3,1.8-4.3,4.4c0,2.6,1.6,4.4,4.3,4.4c2.7,0,4.3-1.8,4.3-4.4 C95.8,37.4,94.2,35.6,91.5,35.6 M100.7,48.7h-4.9v-2.3h-0.1c-0.8,1.2-2.9,2.7-5.3,2.7c-5.1,0-8.5-3.7-8.5-8.9c0-4.8,3-9.2,7.9-9.2 c2.2,0,4.3,0.6,5.5,2.3h0.1v-10h5.3V48.7z'
    />
    <path className='st0' d='M139.4,18h-30.7c-1.5,0-2.7,1.2-2.7,2.6v30.8c0,1.4,1.2,2.6,2.7,2.6h30.7c1.5,0,2.7-1.2,2.7-2.6V20.6 C142.1,19.2,140.9,18,139.4,18z M116.7,48.7h-5.3V31.5h5.3V48.7z M114.1,29.1c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1 s3.1,1.4,3.1,3.1C117.2,27.8,115.8,29.1,114.1,29.1z M136.7,48.7h-5.3v-8.4c0-2,0-4.6-2.8-4.6s-3.2,2.2-3.2,4.4v8.5h-5.3V31.5h5.1 v2.3h0.1c0.7-1.4,2.5-2.8,5.1-2.8c5.4,0,6.4,3.6,6.4,8.2L136.7,48.7L136.7,48.7z'
    />
    <g>
        <path className='st0' d='M7.6,3.4v1.8H2.1v3.6h4.6v1.8H2.1v5h-2.3V3.4H7.6z'
        />
        <path className='st0' d='M24.4,9.5c0,0.9-0.1,1.7-0.4,2.5c-0.3,0.8-0.7,1.4-1.3,2c-0.5,0.6-1.2,1-2,1.3c-0.8,0.3-1.6,0.5-2.5,0.5 c-0.9,0-1.8-0.2-2.5-0.5c-0.8-0.3-1.4-0.7-2-1.3c-0.5-0.6-1-1.2-1.3-2c-0.3-0.8-0.4-1.6-0.4-2.5c0-0.9,0.1-1.7,0.4-2.5 c0.3-0.8,0.7-1.4,1.3-2c0.5-0.6,1.2-1,2-1.3s1.6-0.5,2.5-0.5c0.6,0,1.2,0.1,1.7,0.2c0.5,0.1,1,0.3,1.5,0.6c0.5,0.3,0.9,0.6,1.2,1 c0.4,0.4,0.7,0.8,0.9,1.3c0.3,0.5,0.4,1,0.6,1.5C24.3,8.3,24.4,8.9,24.4,9.5z M22,9.5c0-0.7-0.1-1.3-0.3-1.8 c-0.2-0.5-0.4-1-0.8-1.4c-0.3-0.4-0.7-0.7-1.2-0.9c-0.5-0.2-1-0.3-1.6-0.3c-0.6,0-1.1,0.1-1.6,0.3c-0.5,0.2-0.9,0.5-1.2,0.9 c-0.3,0.4-0.6,0.8-0.8,1.4s-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8s0.4,1,0.8,1.4c0.3,0.4,0.7,0.7,1.2,0.8c0.5,0.2,1,0.3,1.6,0.3 c0.6,0,1.1-0.1,1.6-0.3c0.5-0.2,0.9-0.5,1.2-0.8c0.3-0.4,0.6-0.8,0.8-1.4C21.9,10.8,22,10.2,22,9.5z'
        />
        <path className='st0' d='M32,13.8h4.9v1.9h-7.2V3.4H32V13.8z' />
        <path className='st0' d='M44.2,13.8h4.9v1.9h-7.2V3.4h2.3V13.8z' />
        <path className='st0' d='M65.3,9.5c0,0.9-0.1,1.7-0.4,2.5c-0.3,0.8-0.7,1.4-1.3,2c-0.5,0.6-1.2,1-2,1.3c-0.8,0.3-1.6,0.5-2.5,0.5 c-0.9,0-1.8-0.2-2.5-0.5c-0.8-0.3-1.4-0.7-2-1.3c-0.5-0.6-1-1.2-1.3-2c-0.3-0.8-0.4-1.6-0.4-2.5c0-0.9,0.1-1.7,0.4-2.5 c0.3-0.8,0.7-1.4,1.3-2c0.5-0.6,1.2-1,2-1.3s1.6-0.5,2.5-0.5c0.6,0,1.2,0.1,1.7,0.2c0.5,0.1,1,0.3,1.5,0.6c0.5,0.3,0.9,0.6,1.2,1 c0.4,0.4,0.7,0.8,0.9,1.3c0.3,0.5,0.4,1,0.6,1.5C65.2,8.3,65.3,8.9,65.3,9.5z M63,9.5c0-0.7-0.1-1.3-0.3-1.8 c-0.2-0.5-0.4-1-0.8-1.4c-0.3-0.4-0.7-0.7-1.2-0.9c-0.5-0.2-1-0.3-1.6-0.3c-0.6,0-1.1,0.1-1.6,0.3C57,5.7,56.6,6,56.3,6.4 c-0.3,0.4-0.6,0.8-0.8,1.4s-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8s0.4,1,0.8,1.4c0.3,0.4,0.7,0.7,1.2,0.8c0.5,0.2,1,0.3,1.6,0.3 c0.6,0,1.1-0.1,1.6-0.3c0.5-0.2,0.9-0.5,1.2-0.8c0.3-0.4,0.6-0.8,0.8-1.4C62.9,10.8,63,10.2,63,9.5z'
        />
        <path className='st0' d='M69.2,3.4h1.9c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.4l2.1,7.3c0,0.2,0.1,0.4,0.1,0.6 c0,0.2,0.1,0.4,0.1,0.7c0-0.2,0.1-0.5,0.1-0.7c0.1-0.2,0.1-0.4,0.2-0.6l2.4-7.3c0-0.1,0.1-0.2,0.3-0.4c0.1-0.1,0.3-0.2,0.5-0.2 h0.7c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.4l2.4,7.3c0.1,0.3,0.2,0.8,0.3,1.2c0.1-0.4,0.2-0.8,0.2-1.2l2.1-7.3 c0-0.1,0.1-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.5-0.2h1.8l-3.8,12.2h-2.1l-2.7-8.4c0-0.1-0.1-0.2-0.1-0.3C78,6.8,78,6.7,78,6.5 c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.3l-2.7,8.4H73L69.2,3.4z'
        />
        <path className='st0' d='M104,13.8c0.4,0,0.8-0.1,1.2-0.2c0.3-0.1,0.6-0.4,0.9-0.6c0.2-0.3,0.4-0.6,0.5-1s0.2-0.8,0.2-1.3V3.4h2.3v7.3 c0,0.7-0.1,1.4-0.4,2c-0.2,0.6-0.6,1.1-1,1.6c-0.4,0.4-1,0.8-1.6,1c-0.6,0.3-1.3,0.4-2.1,0.4c-0.8,0-1.5-0.1-2.1-0.4 c-0.6-0.3-1.2-0.6-1.6-1c-0.4-0.4-0.8-1-1-1.6c-0.2-0.6-0.4-1.3-0.4-2V3.4h2.3v7.3c0,0.5,0.1,0.9,0.2,1.3c0.1,0.4,0.3,0.7,0.5,1 s0.5,0.5,0.9,0.6S103.6,13.8,104,13.8z'
        />
        <path className='st0' d='M121.5,5.6c-0.1,0.1-0.1,0.2-0.2,0.3C121.2,5.9,121.1,6,121,6c-0.1,0-0.2,0-0.4-0.1c-0.1-0.1-0.3-0.2-0.5-0.3 c-0.2-0.1-0.4-0.2-0.7-0.3c-0.3-0.1-0.6-0.1-0.9-0.1c-0.3,0-0.6,0-0.8,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.3-0.4,0.5 c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.3,0.1,0.5,0.2,0.7c0.2,0.2,0.4,0.4,0.6,0.5c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.7,0.2,1,0.3 c0.4,0.1,0.7,0.3,1,0.4c0.3,0.2,0.6,0.4,0.9,0.6c0.3,0.2,0.5,0.5,0.6,0.9c0.2,0.4,0.2,0.8,0.2,1.3c0,0.6-0.1,1.1-0.3,1.6 c-0.2,0.5-0.5,0.9-0.8,1.3c-0.4,0.4-0.8,0.6-1.3,0.8c-0.5,0.2-1.1,0.3-1.8,0.3c-0.4,0-0.8,0-1.1-0.1c-0.4-0.1-0.7-0.2-1.1-0.3 c-0.3-0.1-0.7-0.3-1-0.5c-0.3-0.2-0.6-0.4-0.8-0.7l0.7-1.1c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1 c0.1,0,0.3,0.1,0.5,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.5,0.3,0.8,0.4c0.3,0.1,0.7,0.2,1.1,0.2c0.6,0,1.2-0.2,1.5-0.5 c0.4-0.3,0.5-0.7,0.5-1.3c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.2-0.4-0.4-0.6-0.5c-0.3-0.1-0.6-0.3-0.9-0.4c-0.3-0.1-0.7-0.2-1-0.3 c-0.3-0.1-0.7-0.2-1-0.4c-0.3-0.2-0.6-0.4-0.9-0.6c-0.3-0.3-0.5-0.6-0.6-1c-0.2-0.4-0.2-0.8-0.2-1.4c0-0.4,0.1-0.9,0.3-1.3 c0.2-0.4,0.4-0.8,0.8-1.1c0.3-0.3,0.8-0.6,1.3-0.8c0.5-0.2,1.1-0.3,1.7-0.3c0.7,0,1.4,0.1,2,0.3c0.6,0.2,1.1,0.5,1.6,0.9 L121.5,5.6z'
        />
    </g>
</svg>
  )
}
