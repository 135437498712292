import React from 'react'
import { Link } from 'gatsby'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import Button from './Button'
import { LogoIcon } from '../Icons'
import { useLocalStorage } from '../Hooks'

const Container = styled.div`
  position: fixed;
  top: 0;
  background-color: rgba(43, 105, 216, 0.95);
  color: ${props => props.theme.white};
  padding: 1em 0;
  width: 100vw;
  z-index: 2000;
  @media screen and (min-width: 1000px) {
    top: initial;
    bottom: 0;
    max-width: 45ch;
    padding: 2em 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 3em;
  @media screen and (min-width: 1200px) {
    justify-content: space-around;
  }
`

const Text = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  color: ${props => props.theme.white};
  line-height: 1.8;
  margin: 0 auto;
  font-size: 0.8em;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em 0;
`

const PrivacyLink = styled(Link)`
  color: ${props => props.theme.white};
  transition: 0.3s;
  :hover {
    color: ${props => props.theme.highlight};
    transition: 0.3s;
  }
`

const PrivacyBanner = () => {
  const [newUser, setNewUser] = useLocalStorage('newUser')

  const handleClick = e => {
    const gaProperty = 'UA-144702057-1'
    const disableStr = `ga-disable${gaProperty}`
    if (document.cookie.indexOf(`${disableStr}= true`) > -1) {
      window[disableStr] = true
    }
    function gaOptout() {
      document.cookie = `${disableStr}= true; expires = Thu, 31 Dec 2099 23:59:59 UTC; path = / `
      window[disableStr] = true
    }
    setNewUser(false)
    if (e.target.name === 'decline') {
      gaOptout()
    }
  }

  return (
    <>
      {newUser === null ? (
        <Container>
          <Content>
            <Text>
              Like most websites <LogoIcon fill="#fff" height="1ch" /> uses cookies to deliver a personalised,
              responsive service and to improve the site. We remember and store information about how you use it. This
              is done using simple text files called cookies which sit on your computer. These cookies are completely
              safe, secure and will never contain any sensitive information. They are used only by{' '}
              <LogoIcon fill="#fff" height="1ch" /> and our selected third parties. By clicking "Accept", you confirm
              you agree to our Privacy and Cookie Policy
            </Text>
            <Buttons>
              <Button name="accept" onClick={handleClick} title="accept & Close" />
              <Button name="decline" onClick={handleClick} title="Decline" />
            </Buttons>
            <small>
              We've recently updated our Privacy and Cookies Policy to give you more detailed information about your
              rights and how we use your data. <PrivacyLink to="/privacy">View Privacy Policy</PrivacyLink>
            </small>
          </Content>
        </Container>
      ) : null}
    </>
  )
}

export default PrivacyBanner
