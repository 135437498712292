import React from 'react'

export default function MoreIcon(props) {
  return (
    <svg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='m328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z'
      />
    </svg>
  )
}
